var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "grey lighten-3 px-3", attrs: { id: "features" } },
    [
      _c("section-header", {
        attrs: {
          header: "Research",
          "sub-header": "Universal access to data and computation",
          text:
            "Nuvolos can maximize the returns of institutional data subscriptions through simple web-based and application access."
        }
      }),
      _c(
        "v-container",
        {
          attrs: {
            "mt-5": _vm.$vuetify.breakpoint.mdAndUp,
            "pt-5": _vm.$vuetify.breakpoint.mdAndUp,
            "px-0": "",
            "pb-0": ""
          }
        },
        _vm._l(_vm.features, function(feature, i) {
          return _c(
            "v-row",
            {
              key: i,
              class: [_vm.$vuetify.breakpoint.mdAndUp && "my-5"],
              style: {
                flexDirection:
                  _vm.$vuetify.breakpoint.mdAndUp && i === 1
                    ? "row-reverse"
                    : "row"
              },
              attrs: { align: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: { cols: "12", md: "6" }
                },
                [_c("ImageDialog", { attrs: { src: feature.src } })],
                1
              ),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c("h3", {
                  staticClass: "headline font-weight-regular mb-3 mt-2",
                  domProps: { textContent: _vm._s(feature.header) }
                }),
                _c("p", {
                  staticClass: "body-1 font-weight-light",
                  domProps: { textContent: _vm._s(feature.text) }
                })
              ]),
              i !== _vm.features.length - 1
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-5 hidden-sm-and-down",
                      attrs: { cols: "12", "order-xs": "4" }
                    },
                    [_c("v-divider")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }