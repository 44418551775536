<template>
    <section id="features" class="grey lighten-3 px-3">
        <section-header
            header="Research"
            sub-header="Universal access to data and computation"
            text="Nuvolos can maximize the returns of institutional data subscriptions through simple web-based and application access."
        />

        <v-container :mt-5="$vuetify.breakpoint.mdAndUp" :pt-5="$vuetify.breakpoint.mdAndUp" px-0 pb-0>
            <v-row
                v-for="(feature, i) in features"
                :key="i"
                :class="[$vuetify.breakpoint.mdAndUp && 'my-5']"
                align="center"
                :style="{
                    flexDirection: $vuetify.breakpoint.mdAndUp && i === 1 ? 'row-reverse' : 'row'
                }"
            >
                <v-col cols="12" md="6" class="hidden-sm-and-down">
                    <ImageDialog :src="feature.src"></ImageDialog>
                </v-col>
                <v-col cols="12" md="6">
                    <h3 class="headline font-weight-regular mb-3 mt-2" v-text="feature.header" />
                    <p class="body-1 font-weight-light" v-text="feature.text" />
                </v-col>
                <v-col v-if="i !== features.length - 1" cols="12" class="py-5 hidden-sm-and-down" order-xs="4">
                    <v-divider />
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
const ImageDialog = () => import('../ImageDialog')

export default {
    components: { ImageDialog },
    data: () => ({
        features: [
            {
                src: require('@/assets/feature-query.png'),
                header: 'Work with data of any size',
                text:
                    'Nuvolos supports datasets of any size, providing a single location to host all institutional data. Data can be accessed by all affiliates with a valid licence, through a powerful web query interface providing important context such as table and column comments.'
            },
            {
                src: require('@/assets/feature-rstudio.png'),
                header: 'Access data',
                text:
                    'Connect directly with applications running on Nuvolos or from your own computer. Applications running on Nuvolos are configured to immediately access and work with all institutional data.'
            },
            {
                src: require('@/assets/feature-files.png'),
                header: 'A complete environment',
                text:
                    'Nuvolos provides a simple interface to work with files, create snapshots of the complete research environment and track changes to code over time.'
            }
        ]
    })
}
</script>
